import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ArticleService } from '../services/article.service';
import { DomSanitizer, Meta, Title, platformBrowser } from '@angular/platform-browser';
import { env } from '../config/config';
import { SharedService } from '../services/shared.service';
import { PlatformState } from '@angular/platform-server';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrl: './index.component.scss'
})
export class IndexComponent implements OnInit, OnDestroy {
    xmlData: string;
    parser!: DOMParser;
    xmlData_dupe: any;
    IndexLoading = true;
    @ViewChild('indexContent') contentElement: ElementRef<HTMLDivElement>;
    alphabets: string[] = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));

    constructor(private articleService: ArticleService, private sanitizer: DomSanitizer,
        private meta: Meta, private title: Title, private journal: SharedService,
        @Inject(PLATFORM_ID) private platformId: any,
    ) {
        if (platformBrowser(this.platformId)) this.parser = new DOMParser();
    }

    ngOnInit() {
        this.meta.updateTag({ property: 'og:url', content: `${env.siteUrl}/page/index` });
        this.meta.updateTag({ property: 'og:type', content: 'article' });
        this.meta.updateTag({ property: 'og:title', content: `Index | International Textbook of Leprosy` });
        // this.meta.updateTag({ property: 'og:description', content });

        // Optionally update the title
        this.title.setTitle('Index |International Textbook of Leprosy');
        this.articleService.getIndexData().subscribe(result => {
            this.IndexLoading = false;

            // this.xmlData = result.data;
            this.restructureXml(result.data);
            if (platformBrowser(this.platformId)) {

            }
        })
    }

    restructureXml(data: string) {
        if (data) {
            const html = this.parser?.parseFromString(data, 'text/html');

            // to hide front tag
            const content = html?.querySelector("[class='front']");
            content?.classList.add('hide');

            html?.querySelectorAll("[class='sec']").forEach(node => {
                node.classList.remove('sec');
                node.classList.add('secIndex');
            })

            const serializer = new XMLSerializer();
            this.xmlData = serializer.serializeToString(html);
            this.xmlData_dupe = this.sanitizer.bypassSecurityTrustHtml(this.xmlData);

        }

    }

    focusToAlphabets(alphabet: string) {

        this.contentElement?.nativeElement.querySelectorAll('.title').forEach(ele => {
            if (ele.textContent == alphabet) {
                ele.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                })
                ele.classList.add('highlightAlph');
                setTimeout(() => {
                    ele.classList.remove('highlightAlph');
                }, 1000);
            }
        })
    }
    ngOnDestroy(): void {
        this.meta.updateTag(
            { property: 'og:site_name', content: env.siteUrl },
        );
        this.meta.updateTag(
            { property: 'og:url', content: env.siteUrl },
        );
        this.meta.updateTag(
            { property: 'og:title', content: "International Textbook of Leprosy" },
        );


        this.meta.updateTag(
            { property: 'twitter:title', content: "International Textbook of Lepros" },
        );
        this.meta.updateTag(
            { property: 'twitter:card', content: "summary" },
        );
        this.meta.updateTag(
            { property: 'twitter:url', content: env.siteUrl },
        );
    }
}